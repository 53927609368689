.analytic-cards{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.analytic-cards .analytic-card{
    width: 23%;
}
@media (max-width : 1450px) {
    .analytic-cards .analytic-card{
        width: 45%;
    }
}
@media (max-width : 575px) {
    .analytic-cards .analytic-card{
        width: 100%;
    }
}


.analytic-charts{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.analytic-charts .analytic-chart .card{
    height: 350px;
}
.analytic-charts .analytic-chart{
    width: 32%;
}
@media (max-width : 1450px) {
    .analytic-charts .analytic-chart{
        width: 45%;
    }
}
@media (max-width : 767px) {
    .analytic-charts .analytic-chart{
        width: 100%;
    }
}