/* Add this CSS to your stylesheet */
.custom-button {
    background-color: gray!important; /* Set your desired background color */
    border-color: #6c757d!important;     /* Set your desired border color */
    color: #fff;               /* Set your desired text color */
  }
  
  .custom-button:hover {
    background-color: gray!important; /* Keep the same background color on hover */
    border-color: #6c757d !important;     /* Keep the same border color on hover */
    color: #fff;               /* Keep the same text color on hover */
  }
  
.action-button{
    background-color: #F63854;
    border-color: #6c757d;     /* Keep the same border color on hover */
    color: #fff;

}