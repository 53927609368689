/* System Components colors */
/* ///// */

/* system background color */
.content-wrapper {
    background: #f8f8f8 !important;
    padding-bottom: 1px
  }
  
  .background-grey-white {
    background: #f8f8f8 !important;
  }
  
  /* links colors */
  a {
    /* not !important */
    color: #f63854;
  }
  
  .a-color {
    color: #f63854 !important;
  }
  
  .history-link {
    color: rgb(65, 132, 255) !important;
  }
  
  /* Grey color */
  .gcolor {
    color: grey;
  }
  
  /* ///// */
  
  /* Recieved/Sent comment box */
  .comment {
    background: white;
    padding: 0.5em;
    padding-top: 0;
    white-space: pre-wrap;
    word-break: break-word;
    border-radius: 0.3em !important;
  }
  
  /* table page selector */
  .page-link {
    border: none;
    color: #555566;
    background-color: #f8f8f8 !important;
  }
  .page-link:hover {
    color: #f63854;
  }
  .page-item {
    background-color: #f8f8f8 !important;
  }
  .page-item.disabled .page-link {
    color: #cfcfcf;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
  }
  .page-item.active .page-link {
    background-color: #ffebee !important;
    color: #f63854 !important;
  }
  .page-item:hover .page-link {
    /* background-color: #FFEBEE; */
    color: #f63854 !important;
  }
  
  /* Tags */
  .primary-tag {
    padding: 5px 10px 5px 10px;
    border-radius: 2em !important;
    border: 0 solid !important;
    color: #f63854 !important;
    background-color: #ffebee !important;
  }
  .default-tag {
    padding: 5px 10px 5px 10px;
    color: grey !important;
    background-color: transparent !important;
    border-radius: 2em !important;
    border: 1px solid #8e8e8e !important;
  }
  .disabled-tag {
    padding: 5px 10px 5px 10px;
    color: #8e8e8e !important;
    background-color: #e2e2e2 !important;
    border-radius: 2em !important;
    border: 0 solid #8e8e8e43 !important;
  }
  
  /* button styles */
  /* ///// */
  
  .btn {
    border-radius: 8px;
  }
  .btn-primary,
  .primary-btn {
    background-color: #f63854 !important;
    color: white !important;
    border: 1px solid #ff99a8 !important;
  }
  .btn-primary:hover,
  .primary-btn:hover {
    background-color: #d9314a !important;
    color: white !important;
    border: 1px solid #d9314a !important;
  }
  .btn-primary.disabled,
  .btn-primary:disabled,
  .primary-btn:disabled {
    background-color: #e6e6e6 !important;
    color: #999999 !important;
    border: none !important;
  }
  /* .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background-color: #c72c44 !important;
    color: white !important;
    border: none !important;
  }
  .btn-primary:not(:disabled):not(.disabled).hover, .btn-primary:not(:disabled):not(.disabled):hover, .show>.btn-primary.dropdown-toggle{
    background-color: #c72c44 !important;
    color: white !important;
    border: none !important;
  } */
  
  .secondary-btn {
    color: #f63854 !important;
    background-color: white !important;
    border: 1px solid #e6e6e6 !important;
  }
  .secondary-btn:hover {
    color: #f63854 !important;
    background-color: #ffccd4 !important;
    border: 1px solid #f63854 !important;
  }
  
  .tertiary-btn {
    color: #f63854 !important;
    background-color: white !important;
  }
  .tertiary-btn:hover {
    color: #f63854 !important;
    background-color: #ffccd4 !important;
  }
  
  .alert-btn {
    color: #f63854 !important;
    background-color: #ffebee !important;
    border: 1px solid #ffccd4 !important;
  }
  .alert-btn:hover {
    color: #f63854 !important;
    background-color: #ffccd4 !important;
    border: 1px solid #ffccd4 !important;
  }
  
  .success-btn {
    color: #14cc52 !important;
    background-color: #ccffdd !important;
    border: 1px solid #bbeacb !important;
  }
  .success-btn:hover {
    color: #14cc52 !important;
    background-color: #bbeacb !important;
    border: 1px solid #bbeacb !important;
  }
  
  .sm-btn {
    font-size: 12px;
    border-radius: 5px;
    max-height: 26px;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  
  .tab-btn {
    color: #666666 !important;
    background-color: transparent !important;
  }
  .tab-btn:hover {
    color: #f63854 !important;
    background-color: #ffccd4 !important;
  }
  
  .default-secondary-btn {
    color: #f63854 !important;
    background-color: #ffebee !important;
  }
  .transparent-btn {
    color: #454545 !important;
    background-color: transparent !important;
  }
  
  /* nav */
  .nav-tabs {
    border-bottom: 0 !important;
  }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border-style: none !important;
    border-radius: 8px !important;
  }
  .nav-pills .nav-link.active {
    color: #f63854 !important;
    background-color: #ffebee !important;
  }
  .nav-pills .nav-link:not(.active):hover {
    color: #f63854;
    background: transparent;
  }
  
  /* Need to further check use for button class*/
  .button {
    position: relative;
    padding: 8px 16px;
    background: #f63854;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:active {
    background: #c72c44;
  }
  
  .button--loading::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-color: #c72c44;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  .deactivate-btn {
    /* font-weight: bold; */
    color: #f63854 !important;
    background-color: white !important;
    border: 1px solid #f63854 !important;
  }
  
  .activate-btn {
    /* font-weight: bold; */
    color: #009942 !important;
    background-color: white !important;
    border: 1px solid #009942 !important;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
  
  /* ///// */
  
  /* table style */
  /* ///// */
  
  .table {
    border: none !important;
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
  }
  
  /* for advanced asset */
  /* tbody th.achild
  {
      padding-left: 2em !important;
  }
  tbody th.aGchild{
      padding-left: 4em !important;
  }
  tbody th.aGGchild{
      padding-left: 6em !important;
  } */
  .achild {
    padding-left: 2em !important;
  }
  .aGchild {
    padding-left: 4em !important;
  }
  .aGGchild {
    padding-left: 6em !important;
  }
  /* ///// */
  
  /* headers */
  h2 {
    color: rgb(59, 59, 59);
  }
  
  /* font styles */
  .font-success {
    color: green;
  }
  .small-font {
    font-size: 12px !important;
  }
  .medium-title {
    font-size: 18px !important;
  }
  
  /* form inputs */
  /* ///// */
  input[type='text'] {
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    box-shadow: inset 0 0 4px #e4e4e4;
    border-radius: 8px;
  }
  input[type='text']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='text']:focus {
    background: #f8f8f8;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='text']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: 1px solid #e9ecefda;
    box-shadow: none;
  }
  
  input[type='number'] {
    background-color: #f8f8f8;
    border: #f8f8f8;
    box-shadow: inset 0 0 4px #e4e4e4;
    border-radius: 8px;
  }
  input[type='number']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='number']:focus {
    background: #f8f8f8;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='number']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: 1px solid #e9ecefda;
    box-shadow: none;
  }
  
  b,
  strong {
    font-weight: bolder;
    font-size: 18px;
  }
  
  input[type='email'] {
    background-color: #f8f8f8;
    border: #f8f8f8;
    box-shadow: inset 0 0 4px #e4e4e4;
    border-radius: 8px;
  }
  input[type='email']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='email']:focus {
    background: #f8f8f8;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='email']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: none;
    box-shadow: none;
  }
  
  input[type='password'] {
    background-color: #f8f8f8;
    border: #f8f8f8;
    box-shadow: inset 0 0 4px #e4e4e4;
    border-radius: 8px;
  }
  input[type='password']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='password']:focus {
    background: #f8f8f8;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='password']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: none;
    box-shadow: none;
  }
  
  input[type='file'] {
    background-color: #f8f8f8;
    border: #f8f8f8;
  }
  input[type='file']:focus {
    background-color: #f8f8f8;
    border: #f8f8f8;
  }
  
  input[type='email'] {
    background-color: #f8f8f8;
    border: #f8f8f8;
  }
  input[type='email']:focus {
    background: white;
    border: 2px solid #eeeeee;
  }
  
  textarea {
    background-color: #f8f8f8 !important;
    /* border: 1px solid #F8F8F8 !important; */
    border: 1px solid #f8f8f8;
    border-radius: 8px !important;
    box-shadow: none !important;
  }
  textarea:hover {
    background: #ffffff !important;
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
  }
  textarea:focus {
    background: #f8f8f8 !important;
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
  }
  
  input[type='datetime-local'] {
    background: #f8f8f8;
    border: #f8f8f8;
    color: grey;
    border-radius: 8px;
    box-shadow: inset 0 0 4px #e4e4e4;
  }
  input[type='datetime-local']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='datetime-local']:focus {
    background: #f8f8f8;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='datetime-local']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: none;
    box-shadow: none;
  }
  
  input[type='date'] {
    background: #f8f8f8;
    border: #f8f8f8;
    color: grey;
    border-radius: 8px;
    box-shadow: inset 0 0 4px #e4e4e4;
  }
  input[type='date']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='date']:focus {
    background: #f8f8f8 !important;
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
  }
  input[type='date']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: none;
    box-shadow: none;
  }
  
  input[type='time'] {
    background: #f8f8f8;
    border: #f8f8f8;
    color: grey;
    border-radius: 8px;
    box-shadow: inset 0 0 4px #e4e4e4;
  }
  input[type='time']:hover {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  input[type='time']:focus {
    background: #f8f8f8 !important;
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
  }
  input[type='time']:read-only {
    background: #e9ecefda;
    color: #979ba1 !important;
    border: none;
    box-shadow: none;
  }
  
  select{
      background: #f8f8f8 ;
      border: 1px #F8F8F8 ;
      border-radius: 8px ;
      box-shadow: inset 0 0 4px #e4e4e4 ;
  }
  select:hover{
      background: #ffffff ;
      border: 1px solid #E6E6E6 ;
      box-shadow: none ;
  }
  select:focus{
      background: #F8F8F8 ;
      border: 1px solid #E6E6E6 ;
      box-shadow: none ;
  }
  select:read-only{
      background: #e9ecefda;
      color: #979ba1 ;
      border: none;
      box-shadow: none;
  }
  
  .search-bar {
    background-color: white !important;
    border-radius: 8px !important;
    padding-left: 1em !important;
    height: 2.4em !important;
    box-shadow: 0 2px 4px #e4e4e475 !important;
  }
  .search-bar:hover {
    background-color: white !important;
    border: 1px solid #e6e6e6 !important;
    border-radius: 8px !important;
    padding-left: 1em !important;
    height: 2.4em !important;
    box-shadow: none !important;
  }
  .search-bar:active {
    background-color: #f8f8f8 !important;
    border: 1px solid #e6e6e6 !important;
    border-radius: 8px !important;
    padding-left: 1em !important;
    height: 2.4em !important;
    box-shadow: none !important;
  }
  
  /* card style */
  .card {
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.7em !important;
    box-shadow: none !important;
  }
  .card-header {
    border: none;
  }
  
  /* Misc */
  .login-box {
    border-style: solid !important;
    border-color: #e0e0e0 !important;
    background-color: white !important;
    border-width: 3px !important;
    padding: 2.5em;
    padding-bottom: 5em;
    border-radius: 0.7em;
    width: 25em;
  }
  
  .dropdown-menu {
    min-width: 3em !important;
    max-width: fit-content;
    text-align: left;
    padding: 0.7em;
  }
  
  .img-company {
    border-radius: 20%;
    /* box-shadow: 0 1px 4px #b9b9b973 !important; */
    border: solid 1px #ffebee;
    background-color: white;
  }
  
  @media screen {
    .qr-print {
      display: none;
    }
  
  }
  @media print {
    /* body * {
      visibility: hidden;
    }
    #printSectionBuilding,
    #printSectionBuilding * {
      visibility: visible;
      margin-left: 12.5%;
    }
    #printSectionBuilding {
      position: absolute;
      top: 0;
    } */
    .qr-print {
      display: block;
      
    }
  }
  
  .fa-question-circle {
    position: relative;
    display: inline-block;
  }
  
  .fa-exclamation-circle {
    position: relative;
    display: inline-block;
  }
  
  .fa-check-circle {
    position: relative;
    display: inline-block;
  }
  .fa-info-circle {
    position: relative;
    display: inline-block;
  }
  
  .fa-question-circle .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    width: 200px;
    /* height: 200px; */
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    top: 100%;
    left: 50%;
    margin-left: -100px;
    margin-top: 15px;
    /* box-shadow: 0px 0px 5px grey; */
    box-shadow: -2px -2px 5px grey, -2px 2px 5px grey, 2px -2px 5px grey, 2px 2px 5px grey;
  
    position: absolute;
    z-index: 1;
  
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .fa-exclamation-circle .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    width: 200px;
    /* height: 200px; */
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    top: 100%;
    left: 50%;
    margin-left: -100px;
    margin-top: 15px;
    /* box-shadow: 0px 0px 5px grey; */
    box-shadow: -2px -2px 5px grey, -2px 2px 5px grey, 2px -2px 5px grey, 2px 2px 5px grey;
  
    position: absolute;
    z-index: 1;
  
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .fa-check-circle .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    width: 200px;
    /* height: 200px; */
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    top: 100%;
    left: 50%;
    margin-left: -100px;
    margin-top: 15px;
    /* box-shadow: 0px 0px 5px grey; */
    box-shadow: -2px -2px 5px grey, -2px 2px 5px grey, 2px -2px 5px grey, 2px 2px 5px grey;
  
    position: absolute;
    z-index: 1;
  
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .fa-info-circle .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    width: 200px;
    /* height: 200px; */
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    top: 100%;
    left: 50%;
    margin-left: -100px;
    margin-top: 15px;
    /* box-shadow: 0px 0px 5px grey; */
    box-shadow: -2px -2px 5px grey, -2px 2px 5px grey, 2px -2px 5px grey, 2px 2px 5px grey;
  
    position: absolute;
    z-index: 1;
  
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .fa-exclamation-circle:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .fa-check-circle:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .fa-info-circle:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .fa-question-circle:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .page-profile-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
  .page-profile-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    background-color: #f8f8f8;
    /* change to Fixed positon later */
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .select2-container .select2-selection--single {
    height: 65px !important;
  }
  
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg:hover {
    opacity: 0.7;
  }
  
  /* The Modal (background) */
  .modal-image {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  /* The Modal (background) */
  .modal-specification {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  .modal-delete-image {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  /* Modal Content (image) */
  .modal-content-image {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  /* Add Animation */
  .modal-content-image,
  #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  /* The Close Button */
  .close-image {
    position: absolute;
    top: 50px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  .close-image:hover,
  .close-image:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content-image {
      width: 100%;
    }
  }
  
  .NegativeResult {
    color: #f8f9fa;
  }
  
  .PositiveResult {
    color: #f8f9fa;
  }
  .badge.even-larger-badge {
    font-size: 1.1em;
  }
  
  .font-primary {
    color: black !important;
  }
  
  .btn:hover {
    /* color: #fff !important; */
    text-decoration: none !important;
  }
  
  .font-danger {
    color: red !important;
  }
  
  .background-grey-white {
    background-color: #f8f8f8 !important;
  }
  
  /* wizard */
  
  * {
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
  }
  
  #msform {
    text-align: center;
    position: relative;
    /* margin-top: 20px; */
  }
  
  .select2-container .select2-selection--single {
    height: 38px !important;
    border: 1px solid #ced4da !important;
  }
  
  select.list-dt {
    border: none;
    outline: 0;
    border-bottom: 1px solid #ccc;
    padding: 2px 5px 3px 5px;
    margin: 2px;
  }
  
  select.list-dt:focus {
    border-bottom: 2px solid skyblue;
  }
  
  #asset_wizard {
    z-index: 0;
    border: none;
    border-radius: 0.5rem;
    position: relative;
  }
  
  #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
  }
  
  #progressbar .active {
    color: black;
  }
  
  #progressbar li {
    list-style-type: none;
    font-size: 14px;
    width: 14%;
    float: left;
    position: relative;
  }
  
  #progressbar #asset:before {
    font-family: FontAwesome;
    content: '\f015';
  }
  
  #progressbar #attachment:before {
    font-family: FontAwesome;
    content: '\f574';
  }
  
  #progressbar #statutory-inspection:before {
    font-family: FontAwesome;
    content: '\f56c';
  }
  
  #progressbar #photo:before {
    font-family: FontAwesome;
    content: '\f87c';
  }
  
  #progressbar #finance:before {
    font-family: FontAwesome;
    content: '\f09d';
  }
  #progressbar #asset_location:before {
    font-family: FontAwesome;
    content: '\f1ad';
  }
  
  #progressbar #service:before {
    font-family: FontAwesome;
    content: '\f007';
  }
  
  #progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
  }
  
  #progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
  }
  
  #progressbar li.active:before,
  #progressbar li.active:after {
    background: black;
  }
  
  /* rating */
  
  .rating {
    margin: 0;
    font-size: 24px;
    overflow: hidden;
  }
  
  .rating a,
  .rating label {
    float: right;
    color: #aaa;
    text-decoration: none;
    -webkit-transition: color 0.4s;
    -moz-transition: color 0.4s;
    -o-transition: color 0.4s;
    transition: color 0.4s;
  }
  
  .rating a:hover,
  .rating a:hover ~ a,
  .rating a:focus,
  .rating a:focus ~ a {
    color: orange;
    cursor: pointer;
  }
  .rating2 {
    direction: rtl;
  }
  .rating2 a {
    float: none;
  }
  
  /* rating */
  
  .asset-height {
    max-height: 860px;
    overflow: auto;
    overflow-x: hidden;
  }
  
  .clr-green {
    color: #4f8a11;
  }
  
  .clr-orange {
    color: orange;
  }
  
  .rgfloat {
    float: right;
    margin-left: 10px;
  }
  
  @media only screen and (max-width: 400px) {
    .rgfloat {
      float: none;
      margin-top: 5px;
      width: 100%;
      margin-left: 0 !important;
    }
    .info-box-text {
      font-size: 2.5vw;
    }
    .info-box-number {
      font-size: 3vw;
    }
    .btn {
      font-size: 3vw;
    }
    h3.card-title {
      font-size: 4vw;
    }
  }
  
  .cursor_pointer {
    cursor: pointer;
  }
  
  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    /* transform: scale(0); */
    transition: 0.6s ease opacity, 0.6s ease transform;
  }
  #csv_file {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
  }
  
  .body_style {
    line-height: 1;
    font: 300 17px/1.5 'Roboto', sans-serif;
    /* color: #311B92; */
    /* background-color: #673Ab7 */
  }
  
  h2 {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 20px;
  }
  
  .customRadio input[type='radio'] {
    position: absolute;
    left: -9999px;
  }
  
  .customRadio input[type='radio'] + label {
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
  }
  
  .customRadio input[type='radio'] + label:before {
    content: '';
    background: #fff;
    border: 2px solid #311b92;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .customRadio input[type='radio'] + label:after {
    content: '';
    background: #311b92;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0;
    transform: scale(2);
    transition: transform 0.3s linear, opacity 0.3s linear;
  }
  
  .customRadio input[type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  
  .customCheckbox input[type='checkbox'] {
    position: absolute;
    left: -9999px;
  }
  
  .customCheckbox input[type='checkbox'] + label {
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
    color: rgb(120, 119, 121);
  }
  
  .customCheckbox input[type='checkbox'] + label:before {
    content: '';
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .customCheckbox input[type='checkbox'] + label:after {
    content: '';
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: transparent transparent #311b92 #311b92;
    width: 15px;
    height: 8px;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0;
    transform: scale(2) rotate(-45deg);
    transition: transform 0.3s linear, opacity 0.3s linear;
  }
  
  .customCheckbox input[type='checkbox']:checked + label:after {
    opacity: 1;
    transform: scale(1) rotate(-45deg);
    color: #311b92;
  }
  
  .modal-title {
    font-weight: bold !important;
  }
  
  .modal-header,
  .modal-footer {
    /* border-bottom: 0; */
    border-top: 0;
    max-width: 600px !important;
    position: relative;
  }
  
  .my_checkbox {
    margin-left: 3%;
  }
  
  .modal-footer {
    max-width: 600px !important;
    position: relative;
  }
  
  .modal-footer > :not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .modal-footer > :not(:first-child) {
    margin-left: 0.5rem;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0 auto;
    max-width: 600px;
    width: 600px;
  }
  
  @media only screen and (max-width: 780px) {
    .my_checkbox {
      margin-left: 7%;
    }
  
    .modal-dialog {
      position: relative;
    }
  }
  
  element.style {
  }
  #modal-dialog {
      position: fixed;
      overflow: Auto;
      margin: auto;
      height: 100%;
      right: 0px;
  }
  
  
  .container button focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    outline-width: 0;
  }
  
  @media only screen and (max-width: 580px) {
    .modal-dialog {
      position: relative;
      max-width: 600px;
      width: fit-content;
    }
  
    .my_checkbox {
      margin-left: 6%;
    }
  }
  
  .btn-success {
    /* background-color: #311B92 !important; */
    border-radius: 8px;
    padding-right: 35px;
    padding-left: 35px;
  }
  
  /*enable button can press*/
  .enable-btn {
    border-style: none;
    background: none;
  }
  
  /*disable button cannot press*/
  #disable-btn {
    border-style: none;
    background-color: #eeeeee;
  }
  
  /*hide something*/
  .none {
    display: none;
  }
  
  /*delete icon color*/
  .delete {
    text-decoration: None;
    color: Red;
  }
  
  /*modal dialog*/
  #modal-dialog {
    position: fixed;
    overflow: Auto;
    margin: auto;
    height: 100%;
    right: 0px;
  }
  
  /*modal content*/
  #modal-content {
    height: 100%;
    width: 100%;
    background-color: white;
    overflow: Auto;
  }
  
  /*modal content 2 version*/
  #modal-content2 {
    height: 100%;
    width: 100%;
    overflow: Auto;
  }
  
  /*modal body*/
  #modal-body {
    overflow: Auto;
  }
  
  /*card header background*/
  .card-header-background {
    background-color: #fff;
  }
  .modal-header-background {
    background-color: #f8f8f8;
  }
  /*table header background*/
  .thead {
    background-color: transparent !important;
  }
  
  /* for color inside status and time log */
  .red {
    color: #f63854;
  }
  
  /* For large title */
  .large {
    font-size: 28px;
    font-weight: bold;
  }
  
  /* for modal top spacing */
  .modal-space {
    top: 20%;
  }
  
  /* edit icon border radius */
  .radius {
    border-radius: 5px;
  }
  
  /* photo attachment show */
  .photo {
    border-radius: 8px;
    background: #f8f8f8;
    box-shadow: rgb(214 218 221 / 48%) 3px 3px 3px inset, rgb(255, 255, 255) -4px -4px 4px inset;
  }
  
  /* tab color without showing it */
  .tab {
    background-color: #eeeeee;
    border-radius: 0;
    color: #808080;
  }
  
  /* Table filter design */
  .filter {
    color: #454545;
    font-weight: bold;
  }
  
  /* sticky top design */
  .sticky {
    backdrop-filter: blur(15px);
    box-shadow: 0px 5px 5px #d6dadd60 !important;
    top: 56px;
  }
  
  /* analytics tab color */
  #analytic-tab {
    color: #f63854 !important;
    background-color: #ffebee !important;
  }
  
  /* filter cursor style */
  .filter-cursor {
    font-size: 22px;
    color: #454545;
  }
  
  /* black color */
  .black {
    color: black;
  }
  
  /* filter bottom box color */
  .filter-box {
    background-color: #f1f1f1;
  }
  
  /* Bin width on table */
  .toggleDisplayBin {
    max-width: 3.5em !important;
    text-align: right;
  }
  .toggleDisplayFloorPlan {
    /* max-width: 3.5em !important; */
    text-align: right;
  }
  
  /* Status width */
  .toggleDisplayStatus {
    min-width: 6em;
  }
  
  /* lable width */
  .toggleDisplayLabel {
    min-width: 5em !important;
  }
  
  /* for new work order */
  .blink_me {
    /* animation: blinker 1.5s linear infinite; */
    color: white;
    background: #f63854;
    border-radius: 10px;
    padding: 0 5px 0 5px;
    width: fit-content;
    font-size: xx-small;
    font-weight: normal;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  /* filter settings  */
  #filter-set {
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.5em !important;
  }
  
  /* filter settings  */
  .filter-set {
    border-style: solid !important;
    border-color: #eeeeee !important;
    border-width: 3px !important;
    border-radius: 0.5em !important;
  }
  
  /* add comment */
  .add-comment {
    border-radius: 0.2em;
    font-weight: normal;
  }
  
  /* add comment */
  .add-comment2 {
    border-radius: 0.2em;
    font-weight: normal;
    display: none;
  }
  
  /* cancel comment */
  .cancel-comment {
    border-radius: 0.2em;
    font-weight: normal;
    display: none;
    color: #f63854 !important;
  }
  
  /* overdue in work order */
  .overdue {
    margin-left: 10px;
    color: red;
  }
  
  /* before due in work order */
  .before-due {
    margin-left: 10px;
    color: grey;
  }
  
  /* Complete work order */
  .complete {
    margin-left: 10px;
    color: green;
  }
  
  /* Unassigned status */
  .unassign {
    font-size: 12px;
    color: #004369;
    background-color: #0043692c;
    border-radius: 5px;
  }
  
  /* Scheduled status */
  .schedule {
    font-size: 12px;
    color: #01949a;
    background-color: #01959a2d;
    border-radius: 5px;
  }
  
  /* Started status */
  .start {
    font-size: 12px;
    color: #db1f48;
    background-color: #db1f4827;
    border-radius: 5px;
  }
  
  /* Delayed status */
  .delay {
    font-size: 12px;
    color: #fd931b;
    background-color: #fd941b2a;
    border-radius: 5px;
  }
  
  /* Completed status */
  .complete {
    font-size: 12px;
    color: green;
    background-color: #49ea7f28;
    border-radius: 5px;
  }
  
  /* Other status */
  .other {
    font-size: 12px;
    color: black;
    background-color: #f5f5dc;
    border-radius: 5px;
  }
  
  /* High priority */
  .h-priority {
    font-size: 12px;
    padding: 25px;
    width: 80px;
    color: #db1f48;
    background-color: #db1f4827;
    border-radius: 25px;
  }
  
  /* Medium priority */
  .m-priority {
    font-size: 12px;
    padding: 25px;
    width: 80px;
    color: #e0e000;
    background-color: #ffffe0;
    border-radius: 25px;
  }
  
  /* Low priority */
  .l-priority {
    font-size: 12px;
    padding: 25px;
    width: 80px;
    color: #006400;
    background-color: #98ff98;
    border-radius: 25px;
  }
  
  /* Category on work order setting */
  .category {
    font-size: 12px;
    padding-right: 1em !important;
    padding-left: 1em !important;
    width: fit-content;
    color: #232b2b;
    background-color: #c4c3d0;
    border-radius: 25px;
  }
  
  /* Table width */
  .table-w {
    width: 10em;
  }
  
  select {
    display: block;
    width: 100%;
    background-color: #f8f8f8 !important;
    border: #f8f8f8 !important;
    border-radius: 10px;
    height: 40px;
    padding: 5px 10px;
  }
  
  select:focus {
    outline: none;
  }
  
  @media (max-width: 576px) {
    #deactivate {
      line-height: 18px;
    }
  }
  
  .asset {
    font-size: 16px;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
  }
  .select2-selection__choice {
    color: #000000 !important;
    background-color: #fff !important;
    border-color: #e4e4e4 !important;
  }
  
  /* Scrollbar design */
  /* width */
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 50%;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(155, 155, 155, 0.267) 000;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(90, 90, 90, 0.448);
  }
  
  /* Loading screen */
  #loader {
    background: rgb(255, 255, 255) url(../assets/img/preloader.gif) no-repeat center center;
    background-size: 10%;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
  }
  
  .switch {
    --inactive-bg: #cfd8dc;
    --active-bg: #f63854;
    --size: 1rem;
    appearance: none;
    width: calc(var(--size) * 2.2);
    height: var(--size);
    display: inline-block;
    border-radius: calc(var(--size) / 2);
    cursor: pointer;
    background-color: var(--inactive-bg);
    background-image: radial-gradient(circle calc(var(--size) / 2.1), #fff 100%, #0000 0),
      radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
    background-repeat: no-repeat;
    background-position: calc(var(--size) / -1.75) 0;
    transition: background 0.2s ease-out;
  }
  .switch:checked {
    background-color: var(--active-bg);
    background-position: calc(var(--size) / 1.75) 0;
  }
  
  .asset-icon {
    background: url('../assets/img/tag1.png') no-repeat center center;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  
  .asset-icon.active {
    background: url('../assets/img/tag1-red.png') no-repeat center center;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  
  .inventory-icon {
    background: url('../assets/img/inventory1.png') no-repeat center center;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  
  .checklist-icon {
    background: url('../assets/img/checklist.png') no-repeat center center;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  
  .customization-icon {
    background: url('../assets/img/customize1.png') no-repeat center center;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  
  .custom_Checkbox input[type='checkbox'] {
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
    color: rgb(120, 119, 121);
  }
  
  .custom_Checkbox input[type='checkbox']:before {
    content: '';
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .custom_Checkbox input[type='checkbox']:after {
    content: '';
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: transparent transparent #f63854 #f63854;
    width: 20px;
    height: 10px;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0;
    transform: scale(2) rotate(-45deg);
    transition: transform 0.3s linear, opacity 0.3s linear;
  }
  
  .custom_Checkbox input[type='checkbox']:checked:after {
    opacity: 1;
    transform: scale(1) rotate(-45deg);
    color: #f63854;
  }
  
  .location-details {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .wtree li {
    list-style-type: none;
    margin: 10px 0 10px 30px;
    position: relative;
  }
  .wtree li:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid red;
    border-bottom: 1px solid red;
    width: 20px;
    height: 30px;
  }
  .wtree li:after {
    position: absolute;
    content: '';
    top: 5px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%;
  }
  .wtree li:last-child:after {
    display: none;
  }
  .wtree li span {
    display: block;
    padding: 5px;
    color: red;
    text-decoration: none;
  }
  
  #error-img {
    background: url('../assets/img/errorPage.png') no-repeat center center;
    background-size: 44%;
    width: 75%;
    height: 50vh;
    position: relative;
    z-index: 100;
  }
  
  .progress-bar-container {
    width: 300px;
    margin: auto;
  }
  
  .progress-bar {
    height: 4px;
    background-color: rgb(243, 232, 232);
    width: 100%;
    overflow: hidden;
  }
  
  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(5, 114, 206);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
  }
  
  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
  .sigContainer {
    /* // width: 80%;
    // height: 80%;
    // margin: 0 auto; */
    background-color: #fff;
    height: 200px;
    border-radius:10px ;
  }
  .sigCanvas {
    width: 100%;
    height: 100%;
  }

  .sign-image{
    width: 100%;
    height: 110px;
    margin: 20px auto;
    object-fit:fill;
    box-shadow: 0 0 10px #ccc;
    border-radius: 10px;
  }
  .nosign-image{
    margin: 20px 0;
    text-align: center;
  }
  /* @media print {
    #pdf {
        display: block;
    }
    @page {
    size: auto; 
    margin: 15mm 0 15mm;
    display: block;

    }
} */

@media print {
  @page {
    size: auto; 
    margin: 15mm 0 15mm;
    border-bottom: 1px solid #0000 !important;
    }
}
.print-visible {
  display: block !important;
}
