.inline-main {
    background-color: #f63854 !important;
  }
  .inline-carousel-item {
    background-color: transparent;
    border-radius: 0.7em;
  }
  .inline-maintain-logo {
    width: 100%;
  }
  .inline-login-box {
    background-color: white;
    padding: 2.5em 2.5em 5em;
    border-radius: 0.7em;
    width: 25em;
  }
  .inline-error-box {
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .inline-username {
    background-color: #e0e0e05d;
    border-radius: 0.5em;
    border: none;
    height: 3em;
  }
  
  .inline-password {
    background-color: #e0e0e05d;
    border: none;
    height: 3em;
    border-radius: 0.5em 0 0 0.5em;
  }
  .inline-show-password {
    background-color: #e0e0e05d;
    border-radius: 0 0.5em 0.5em 0;
    border: none;
    height: 3em;
  }
  .inline-forgot-password {
    color: #9b9b9b;
  }
  .inline-login-btn {
    color: white;
    background-color: #f63854;
    border-radius: 0.5em;
    height: 3em;
  }
  .inline-footer {
    color: #9b9b9b;
  }
  