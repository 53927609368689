.nav-link {
  padding-left: 0.7em !important;
  padding-right: 0.7em !important;
  overflow-wrap: break-word;
}

.nav-link .sb-item {
  font-size: small !important;
  font-weight: 500;
}
.nav-sidebar{
  padding-bottom: 20px;
}
.nav-sidebar > .nav-item {
  width: 100%;
}

.notification {
  position: relative;
  text-align: right;
  display: inline-block;
}



.notification:hover .icon-button {
  background: transparent;
}

.notification img {
  padding: 10px 0;
}

.not-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.notification-menu {
  position: absolute;
  top: 36px;
  left: -150px;
  background-color: #fff;
  box-shadow: 0 5px 5px #d6dadd60 !important;
  padding: 6px;
  list-style: none;
  text-align: left;
  border-radius: 5px;
  z-index:2;
  width: 320px;
  height: auto;
  max-height: 540px;
  display: none;
}
.notification-items{
  overflow-y: auto;
  height: auto;
  max-height:400px;
}
@media (max-width : 400px ) {
  .notification-menu {
    position: fixed;
    left: 0;
    width: 100% ;
  }
}
.notification-menu li {
  padding: 6px;
}
.notification-settings{
  cursor: pointer;
  padding: 5px 6px !important;
  margin: 10px 0;
}
.notification-settings:hover{
  background-color: #FFEBEE;
  border-radius: 3px;
}



.notification-menu .avatar {
  padding: 2px 0;
}

.notification-menu h3 {
  font-size: 15px;
  margin: 0 0 5px 0;
  display: inline;
}
.notification-menu p {
  margin-bottom: 0;
  font-size: 14px;
}
.notification-menu .message p ,
.notification-menu .marked-message p {
  cursor: pointer;
}

.notification-menu .date-and-read {
 display: flex;
 justify-content: space-between;
 align-items: center;
 font-size: 13px;
 margin-top:8px ;
}
.notification-menu .date-and-read span{
  color: #999999;
  font-size: 13px;
}
.notification-menu .date-and-read button , .not-actions button {
  border: 1px solid #ccc;
  background-color: white;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: 500;
}
.notification-menu .date-and-read button:hover ,
.not-actions button:hover{
  background-color: #eee;
}

.not-actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.not-actions button:disabled{
  background-color: #eee;
  cursor: not-allowed;
}

.notification .notification-menu-active {
  display: block;
}
.notification-menu .message {
  background-color: #FFEBEE;
  color: black;
  display: block;
  padding: 8px;
  text-decoration: none;
  border-radius: 5px;
}
.notification-menu .marked-message{
  background-color: #fff;
  color: black;
  display: block;
  padding: 8px;
  text-decoration: none;
  border-radius: 5px;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #4caf50;
  color: white;
}

.nav-link.active{
  color: #f63854 !important;
  background-color: #ffebee !important;
}

.nav-pills .nav-link {
  border-radius: 0.3em !important;
  box-shadow: none !important;
}

.badge {
  font-size: 60% !important;
  padding-left: 3px;
  padding-right: 3px;
}

.badge-danger {
  color: #fff;
  background-color: #f63854;
}

.brand-link-styles {
  border-bottom: none !important;
  font-weight: bold;
}

.company-name-styles {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 173px;
}
.profile-name-styles {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  // width: 173px;
  margin :0px 5px;
}

.report-styles {
  font-size: 0.9em !important;
}

.sidenav {
  width: 250px;
  transition: width 0.3s ease-in-out;
  height: 100vh;
  background-color: rgb(10, 25, 41);
  padding-top: 28px;
}
.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 60px;
}


.sideItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
  overflow: hidden;
}
.linkText {
  padding-left: 16px;
}

.sideItem:hover {
  background-color: #244f7d1c;
}
.menuBtn {
  align-self: flex-start;
  justify-self: flex-end;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}

.nav-styles {
  backdrop-filter: blur(15px);
  border-bottom: none;
}

.notification-styles {
  margin-right: 20px;
  border: none;
  outline: none;
}

.notification-icon-styles {
  outline: none;
  border: none;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
}

.icon-button__badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: #f63854;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon-button:hover {
  cursor: pointer;
  background-color:#FFEBEE !important;
}

.icon-button:active {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #cccccc;
}

.profile-link {
  font-weight: bold;
  color: rgb(68, 68, 68);
}

.profile-section {
  padding-top: 2px;
}

.main-layout-div {
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
}

.main-sidebar {
  position: fixed !important;
}

.businessHours-status{
  --inactive-bg: #cfd8dc;
  --active-bg: #F63854;
  --size: 1rem;
  appearance: none;
  width: calc(var(--size) * 2.2);
  height: var(--size);
  display: inline-block;
  border-radius: calc(var(--size) / 2);
  cursor: pointer;
  background-color: var(--inactive-bg);
  background-image: radial-gradient( circle calc(var(--size) / 2.1), #fff 100%, #0000 0 ), radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
  background-repeat: no-repeat;
  background-position: calc(var(--size) / -1.75) 0;
  transition: background 0.2s ease-out;
  margin-right: 10px;
}
.businessHours-status:checked{
  background-color: var(--active-bg);
  background-position: calc(var(--size) / 1.75) 0;
}

