.dive-container {
    display: flex;
  
    align-items: flex-start;
  
    justify-content: space-between;
  
    margin: 20px;
  
    .cards-container {
      display: flex;
  
      gap: 10px;
  
      .card {
        width: 100px;
  
        height: 100px;
  
        display: flex;
  
        align-items: center;
  
        justify-content: center;
  
        background-color: #f0f0f0;
  
        cursor: pointer;
  
        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  
    .data-table {
      border-collapse: collapse;
  
      width: 60%;
  
      th,
      td {
        padding: 8px;
  
        text-align: left;
  
        border-bottom: 1px solid #ddd;
      }
  
      th {
        background-color: #f2f2f2;
      }
  
      tr:hover {
        background-color: #f5f5f5;
      }
    }
  }
  
  .assets {
    margin: 10px 20px 20px;
  }
  
  .assetsTop {
    display: flex;
  
    justify-content: space-between;
  
    align-items: center;
  }
  
  .assetsTitle {
    font-size: 20px;
  }
  
  @media (max-width: 550px) {
    .assetsTop {
      flex-direction: column;
    }
  
    .assetsTitle {
      font-size: 24px;
  
      margin-bottom: 20px;
    }
  }
  
  .assetActions {
    display: flex;
  
    align-items: center;
  }
  
  .assetActions input {
    padding: 10px;
  
    background-color: white;
  
    width: 200px;
  }
  
  .assetActions input::placeholder {
    color: #444;
  }
  
  .assetActions button {
    margin-left: 20px;
  
    display: flex;
  
    justify-content: space-between;
  
    align-items: center;
  
    border-radius: 5px;
  
    cursor: pointer;
  
    border: 1px solid #eee;
  }
  
  .assetActions button:hover:not(:first-of-type) {
    color: #f63854 !important;
  
    background-color: #ffccd4 !important;
  
    border: 1px solid #f63854 !important;
  }
  
  .assetActions button:hover:first-of-type {
    background-color: #d9314a !important;
  
    color: white !important;
  
    border: 1px solid #d9314a !important;
  }
  
  .assetfaPlus {
    background-color: #f63854 !important;
  
    color: white !important;
  
    border: 1px solid #ff99a8 !important;
  }
  
  .assetfaPlus svg {
    color: white;
  
    font-weight: bold !important;
  }
  
  .assetfaPlus a,
  .assetfaPlus span {
    display: block;
  
    padding: 6px 12px;
  }
  
  .assetFilter,
  .assetEllipsis {
    color: #f63854 !important;
  
    background-color: white !important;
  
    border: 1px solid #e6e6e6 !important;
  
    padding: 8px 12px;
  }
  
  .assetFilter svg,
  .assetEllipsis svg {
    color: red;
  }
  
  .assetActions button svg {
    font-size: 18px;
  }
  
  @media (max-width: 550px) {
    .assetActions input {
      padding: 8px;
  
      background-color: white;
  
      width: 150px;
    }
  
    .assetActions button {
      margin-left: 10px;
    }
  
    .assetfaPlus a,
    .assetfaPlus span {
      padding: 4px 8px;
    }
  
    .assetFilter,
    .assetEllipsis {
      padding: 8px 8px;
    }
  
    .assetActions button svg {
      font-size: 15px;
    }
  }
  
  .imports {
    display: flex;
  
    flex-wrap: wrap;
  
    margin: 30px 0 0px;
  }
  
  .imports div {
    background-color: white;
  
    color: red;
  
    padding: 8px 10px;
  
    cursor: pointer;
  
    margin-bottom: 20px;
  
    margin-left: 10px;
  
    display: flex;
  
    align-items: center;
  
    justify-content: center;
  }
  
  .imports div a {
    color: red;
  }
  
  .imports .Importfile input {
    width: 0.1px;
  
    height: 0.1px;
  
    opacity: 0;
  
    overflow: hidden;
  
    position: absolute;
  
    z-index: -1;
  }
  
  .imports .Importfile input + label {
    font-size: 16px;
  
    font-weight: normal;
  
    color: red;
  
    cursor: pointer;
  
    margin-bottom: 0;
  }
  
  /* .imports div:not(:first-of-type){
  
      margin-left: 30px;
  
   
  
  } */
  
  .imports div:hover {
    background-color: rgba(255, 0, 0, 0.2);
  }
  
  .imports div svg {
    margin: 0 5px;
  }
  
  @media (max-width: 895px) {
    .imports div {
      width: 30%;
    }
  }
  
  @media (max-width: 895px) {
    .imports div {
      width: 48%;
  
      text-align: center;
    }
  }
  
  @media (max-width: 550px) {
    .imports {
      display: flex;
  
      justify-content: space-between;
  
      margin: 15px 0;
  
      flex-wrap: wrap;
    }
  
    .imports div {
      background-color: white;
  
      width: 45%;
  
      color: red;
  
      margin: 10px 0;
  
      text-align: center;
    }
  
    .imports div:not(:first-of-type) {
      margin-left: 0px;
    }
  
    .imports div:hover {
      background-color: rgba(255, 0, 0, 0.2);
    }
  
    .imports div svg {
      margin: 0 5px;
    }
  }
  
  @media (max-width: 550px) {
    .imports div {
      background-color: white;
  
      width: 100%;
  
      color: red;
  
      margin: 10px 0;
  
      text-align: center;
    }
  }
  