@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

/* @import '~react-toastify/dist/ReactToastify.min.css'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin-bottom: 0 !important;
}

.pdf-wraper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.pdf-wraper > div{
  width: 48%;
}


.document-wraper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.document-wraper > div{
  width: 48%;
}
@media (max-width : 769px) {
  .document-wraper > div{
    width: 100%;
  }
}

