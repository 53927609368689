select {
    font-size: 16px;
    font-weight: normal;
    color: black;
    height: 3em !important;
  }
  
  .card {
    border: none !important;
  }
  
  // .card-body {
  //   padding-top: 0.5em;
  //   background-color: #f8f8f8;
  // }
  
  th {
    font-weight: lighter;
  }
  
  .card-primary:not(.card-outline) > .card-header {
    background-color: #f8f8f8;
  }
  
  .card-primary:not(.card-outline) > .card-header,
  .card-primary:not(.card-outline) > .card-header a {
    color: black;
  }
  
  .edit-category-title-inline {
    font-size: 17px !important;
  }
  
  .edit-category-card-inline {
    background-color: #f8f8f8;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0em;
    margin: 0em;
  }
  
  .edit-category-card-title-inline {
    font-size: 17px !important;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .custom_tabs{
    color:#F63854
  }
  .card-tools{
    color :#F63854
  }
  .card {
    border: none !important;
  }
  