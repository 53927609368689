.assets {
  margin: 10px 30px 50px;
}

.card_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 3rem;
}

.templates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 40px;
}

@media (max-width : 400px) {
  .templates {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  margin-top: 20px;
  transition: all ease-in-out 0.3s;
}
.ptwcards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  margin-top: 20px;
  transition: all ease-in-out 0.3s;
}

.ptwcards:hover {
  transform: scale(1.04);
}

.ptwcards:last-of-type {
  /* background-color: #65656b; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.ptwcards:hover {
  transform: scale(1.04);
}
.delete-icon-wrapper {
  color: #D9314A;
}

.delete-icon-wrapper:hover {
  color: #D9314A;
  background-color: #D9314A;
}

.cards:last-of-type {
  background-color: #65656b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardActions {
  width: 100%;
  /* background-color: #f63854; */
  /* padding: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cardActionsAdd {
  background-color: #65656b;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.cards h5 {
  margin-bottom: 0;
  padding: 10px 15px 20px;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
}

@media (max-width:350px) {
  .cards h5 {
    font-size: 15px;
  }
}

.cards svg {
  color: white;
}
.ptwcards h5 {
  margin-bottom: 0;
  padding: 10px 15px 20px;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
}
.ptwcards h7 {
  margin-bottom: 0;
  padding: 5px 15px;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  color:#858585
}

@media (max-width:350px) {
  .ptwcards h5 {
    font-size: 15px;
  }
}

/* .ptwcards svg {
  color: #D9314A;
} */

.optionButton {
  background-color: white;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 10px #d6d6d6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.optionButton button {
  color: #1b5cea;
  font-size: 17px;
}

.controlsSp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.controlSp {
  width: 100%;
}

.controlSp p {
  margin-bottom: 0;
}

.controlSp input {
  width: 100%;
  padding-left: 10px;
  outline: black !important;
}

.controlSp input:focus {
  outline: black !important;
}

.controlSpBtn {
  /* height: 40px ; */
  padding: 5px !important;
  display: block;
  color: #f63854 !important;
  background-color: white !important;
  /* border: 1px solid #e6e6e6 !important; */
  /* margin-top: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.controlSpBtn:hover {
  background-color: #f3c1c9 !important;
  border-radius: 5px;
}

.controlSpBtnHov {
  color: #65656b !important;
  padding: 2px !important;
  font-size: 12px !important;
}

.controlSpBtnHov {
  color: #65656b !important;
  padding: 2px !important;
  font-size: 12px !important;
}

.controlSpBtnHov:hover {
  background-color: #dadada !important;
}

.controlsSp:hover .controlSpBtn {
  display: block;
}



.controlsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.actionsSection {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #65656b;
  font-size: 17px;
}

.actionsSection button:hover {
  background-color: #dadada;
}

.actionsSection button {
  padding: 5px 8px;
  border-radius: 5px;
}

.del-bt {
  opacity: 0;
}

.table tbody tr:hover .del-bt {
  opacity: 1;
}

.actionsSection svg {
  font-size: 18px;
}

.controlSection {
  width: calc(90% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  height: 50px;
  padding-right: 10px;
  border-radius: 5px;
  margin-left: 30px;
}

.controlSection input {
  width: 70%;
  padding-left: 10px;
  /* border: 1px solid; */
  padding: 10px;
  border-right: 1px solid #ccc;
  outline: none;
}

.controlSection select {
  width: 30%;
  padding: 10px;
  background-color: transparent !important;
  border-left: 1px solid;
  font-size: 18px;
  font-weight: normal !important;
}

.controlSp input:focus {
  outline: rgb(199, 199, 199) !important;
}

.controlSectionBtn {
  /* height: 40px ; */
  padding: 5px !important;
  color: #f63854 !important;
  background-color: white !important;
  /* border: 1px solid #e6e6e6 !important; */
  font-size: 14px;
  /* margin-top: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.controlSectionBtn:hover {
  background-color: #f3c1c9 !important;
  border-radius: 5px;
}


.hovering {
  color: #65656b !important;
  padding: 2px !important;
  font-size: 12px !important;
}

.hovering:hover {
  background-color: #dadada !important;
}

.controlsSection:hover .controlSectionBtn {
  display: block;
}

/* .controlSection:hover .hovering{
    display: block;
  } */
@media (max-width:500px) {
  .controlsSp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }

  .controlSp {
    width: 40%;
  }

  .controlSp input {
    height: 35px;
    font-size: 12px;
  }

  .controlSp input::placeholder {
    font-size: 12px;
  }

  .controlSpBtn {
    height: 35px;
    padding: 8px !important;
  }
}

.eptwButtons button:first-of-type {
  margin-right: 16px !important;
  background-color: #c07590 !important;
  color: white !important;
}

.eptwButtons button:nth-child(2) {
  margin-right: 16px !important;
  background-color: #d04d6e !important;
  color: white !important;
}
.eptwButtons button:last-of-type {
  background-color: #D9314A !important;
  color: white !important;
}
.eptwButtonsDisabled button:first-of-type {
  margin-right: 16px !important;
  background-color: #c3dac473;
  color: white;
}

.eptwButtonsDisabled button:last-of-type {
  background-color: #f3c1c99d ;
  color: white;
}

.eptwButtons button:first-of-type:hover {
  background-color: #d04d6e !important;
}

.eptwButtons button:last-of-type:hover {
  background-color: #e53935 !important;
}

.threeDButton {
  background-color: #D9314A;
  /* Off-white background color */
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3), 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Add shadows */
  transition: transform 0.3s, box-shadow 0.3s;
  /* Add transition for animation */
  margin-bottom: 2rem;
  color: white;
}

.disableBtn {
  background-color: rgb(142, 142, 142);
  /* border: none; */
  display: inline-block;
  padding: 10px 20px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3), 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Add shadows */

  margin-bottom: 2rem;
  color: white;
}

.threeDButton:hover {
  transform: translateY(-2px);
  /* Move the button slightly up on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4), 0px 4px 8px rgba(0, 0, 0, 0.3);
  /* Adjust shadows on hover */
}


.assetsTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assetsTitle {
  font-size: 20px;
}

@media (max-width :550px) {
  .assetsTop {
    flex-direction: column;
  }

  .assetsTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.assetActions {
  display: flex;
  align-items: center;
}

.assetActions input {
  padding: 10px;
  background-color: white;
  width: 200px;
}

.assetActions input::placeholder {
  /* color: #444; */
  background-color: white;
}


.assetActions button {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  /* border: 1px solid #eee; */
}

.assetActions button:hover:not(:first-of-type) {
  color: #F63854 !important;
  background-color: #FFCCD4 !important;
  border: 1px solid #F63854 !important;
}

.assetActions button:hover:first-of-type {
  background-color: #D9314A !important;
  color: white !important;
  border: 1px solid #D9314A !important;
}

.assetfaPlus {
  background-color: #F63854 !important;
  color: white !important;
  border: 1px solid #FF99A8 !important;
}

.assetfaPlus svg {
  color: white;
  font-weight: bold !important;
}

.assetfaPlus a, .assetfaPlus span {
  display: block;
  padding: 6px 12px;
}

.assetFilter, .assetEllipsis {
  color: #F63854 !important;
  background-color: white !important;
  border: 1px solid #E6E6E6 !important;
  padding: 8px 12px;
}

.assetFilter svg, .assetEllipsis svg {
  color: red;
}

.assetActions button svg {
  font-size: 18px;
}

@media (max-width :550px) {
  .assetActions input {
    padding: 8px;
    background-color: white;
    width: 150px;
  }

  .assetActions button {
    margin-left: 10px;
  }

  .assetfaPlus a, .assetfaPlus span {
    padding: 4px 8px;
  }

  .assetFilter, .assetEllipsis {
    padding: 8px 8px;
  }

  .assetActions button svg {
    font-size: 15px;
  }
}


.imports {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0px;
}

.imports div {
  background-color: white;
  color: red;
  padding: 8px 10px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imports div a {
  color: red;
}


.imports .Importfile input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.imports .Importfile input+label {
  font-size: 16px;
  font-weight: normal;
  color: red;
  cursor: pointer;
  margin-bottom: 0;
}



/* .imports div:not(:first-of-type){
    margin-left: 30px;

} */
.imports div:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.imports div svg {
  margin: 0 5px;
}

@media (max-width :895px) {
  .imports div {
    width: 30%;
  }
}

@media (max-width :895px) {
  .imports div {
    width: 48%;
    text-align: center;
  }
}

@media (max-width :550px) {
  .imports {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    flex-wrap: wrap;
  }

  .imports div {
    background-color: white;
    width: 45%;
    color: red;
    margin: 10px 0;
    text-align: center;
  }

  .imports div:not(:first-of-type) {
    margin-left: 0px;

  }

  .imports div:hover {
    background-color: rgba(255, 0, 0, 0.2);
  }

  .imports div svg {
    margin: 0 5px;
  }

}

@media (max-width :550px) {

  .imports div {
    background-color: white;
    width: 100%;
    color: red;
    margin: 10px 0;
    text-align: center;

  }
}

.templateCard {
  box-shadow: 0 0 10px #ccc;
  padding: 20px 10px;
  border-radius: 10px;
  width: 80%;
  margin: 20px auto;
  text-align: center;
  position: relative;
}

.templateName {
  font-size: 24px;
  margin-bottom: 20px;
}
.templateNamePreview {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.templateSection {
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 20px;
  margin-bottom: 0px;
  text-align: center;
}

.sectionFeilds {
  font-size: 16px;
}

.sectionFeilds label {
  padding-left: 6px;
  width: 100%;
  text-align: left;
}

.templateCheckbox {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.templateCheckbox input {
  width: 40px;
  height: 30px;
  padding-left: 6px;
}
.templateCheckboxSignature input {
  width: 800px;
  height: 50px;
  padding-left: 6px;
}

.assignedImage {
  border-radius: 50%;
  height: 25px;
  aspect-ratio: auto 25 / 25;
  width: 25px;
  margin-left: 0.3rem !important;
}

.sigContainer {
  background-color: #fff;
  height: 200px;
  border-radius:10px ;
}

.sigCanvas {
  width: 100%;
  height: 100%;
}

.sign-image{
  width: 100%;
  height: 50px;
  margin: 20px auto;
  /* object-fit:fill; */
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-image h3 {
  text-align: center;
  color:#858585;
}

.table1{
  width: 100%;
}
.table2{
  width: 100%;
}
.table2 td{
  width: 100%;
  text-align: center;
}
.table3{
  width: 100%;
}

.table3 td{
  text-align: center;
}
.table3 td input{
  width: 80%;
  padding: 5px;
}

.table1 , .table2 , .table3{
  border: 1px solid #000;
}

.table1 td{
  border: 1px solid #000;
  padding: 10px 15px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.table2 td{
  font-weight: bold;
  background-color:#b4c6e7;
  border-radius: 0 !important;
}

.table3 td{
  border: 1px solid #000;
  padding: 5px 10px;
  width: 25%;
}

.table4{
  width: 100%;
  border: 1px solid #000;
  margin-top: 20px;

}

.table4 td{
  text-align: center;
  border: 1px solid #000;
  padding: 5px ;
  border-radius: 0 !important;
}
.table4 thead tr:first-of-type{
  background-color: #92d050;
}
.table4 td input{
  width: 100%;
  padding: 5px;
}