.scrollX{
    overflow-x: scroll;
  }
  .custom_column {
    margin-left: 20px; /* Adjust the value as needed */
  }
  .table{
      width: 100%;
      border: none ;
        border-collapse: separate ;
        border-spacing: 0 10px ;
        padding: 10px;
        background: #f8f8f8 !important;
        overflow: hidden;
        border-radius: 8px !important;
        white-space: nowrap!important;
        padding-bottom: 20px;
      }
      .table thead{
        border-collapse: separate !important;
        border-spacing: 0 10px !important;
        background-color: white !important;
      
      }
      .table thead th{
        font-weight: normal !important;
        color: rgb(168, 168, 168);
        text-align: left;
      }
      
      .table thead th{
        padding: 10px 15px;
        font-size: 14px;
        white-space: nowrap;
  
      }
      .table tbody tr td {
        padding: 10px 10px;
        font-size: 14px;
      text-align: left;
  
      }
      .table tbody tr:hover{
        cursor: pointer;
        visibility: visible;
        background-color: white !important;
        border-radius: 8px !important;
        box-shadow: 0px 3px 5px #ededed;
      }
      .table tbody tr td svg{
          cursor: pointer;
      }
      thead th:last-child, td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
      thead th:first-child , td:first-child {
        border-right-style: solid;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .addChild{
          color: #F63854; 
      }
      .addChild:hover{
          color: #007bff;
      }
      .noResults{
        text-align: center;
        font-size: 24px !important;
      }
  
    .pagination{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0px;
        padding: 5px 15px;
        background-color: white;
      margin-bottom: 30px;
    }
  .pagination .select{
       width: 250px;
       background-color: #f8f8f8;
       padding: 5px 10px;
       border-radius: 0;
       color: black !important;
       font-weight: normal !important;
       font-size: 16px !important;
       height: 40px !important;
       cursor: pointer;
  }
  
  .pagination .buttons{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  }
  
  .pagination .buttons button{
    padding: 5px 10px;
  }
  .pagination .buttons button:hover{
    color: #F63854;
  }
  .pagination .buttons button:disabled{
    color: gray;
  }
  .pagination .buttons span{
    background-color: #FFEBEE !important;
      color: #F63854;
      padding: 7px 15px;
  }
  
  
  @media (max-width :550px ) {
  
    .pagination{
  flex-direction: column;
  }
  .pagination .select{
     margin-bottom: 10px;
     width: 220px;
  }
  
  .pagination .buttons{
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  }
  
  .pagination .buttons button{
  padding: 5px 10px;
  }
  .pagination .buttons button:hover{
  color: #F63854;
  }
  .pagination .buttons button:disabled{
  color: gray;
  }
  .pagination .buttons span{
  background-color: #FFEBEE !important;
    color: #F63854;
    padding: 7px 15px;
  }
  
  }
  .filterTable{
    width: 400px;
    box-shadow: 0 0 10px #a7a7a7;
    border-radius: 10px;
    margin: 20px 0;
    background-color: white;
  
  }
  .filterTable .wraper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .filterTable .wraper h4{
    font-size: 18px;
    margin-bottom: 0 !important;
    margin-left: 15px !important;
  }
  
  .filterTable .checkboxs{
    display: flex;
    flex-wrap: wrap;
    border-bottom-left-radius:10px ;
    border-bottom-right-radius:10px ;
    background-color: #F1F1F1;
    padding: 10px 15px;
  }
  
  .filterTable .checkbox{
    margin-left: 15px;
  }
  .filterTable .checkbox input{
    margin-right: 5px;
  }
  
  @media (max-width :550px ) {
    .filterTable{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 4px;
        border-bottom-left-radius:4px ;
        border-bottom-right-radius:4px ;
        
    }
    .filterTable .wraper h4{
        font-size: 16px;
    }
    
    .filterTable .checkboxs{
        display: flex;
        margin-top: 10px;
    }
    
    .filterTable .checkbox:not(:first-of-type){
        margin-left: 15px;
    }
    .filterTable .checkbox input{
        margin-right: 5px;
    }
    
    .imports{
    display: flex;
    margin: 15px 0;
    flex-wrap: wrap;
    }
    .imports div{
        background-color: white;
        width: 50%;
        color: red;
        padding: 8px 10px;
      
    }
    .imports div:not(:first-of-type){
        margin-left: 30px;
    
    }
    .imports div:hover{
        background-color: rgba(255, 0, 0 ,0.2);
    }
    .imports div svg{
        margin: 0 5px;
    }
  
  }
  
  .delay {
    color: #FD931B;
    background-color: #fd941b2a;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    width: 120px;
    display: inline-block;
    text-align: center;
  }
  
  .other {
    color: black;
    background-color: #f5f5dc;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    width: 120px;
    display: inline-block;
    text-align: center;
  }
  .started{
      color: #DB1F48;
      background-color: #db1f4827;
      border-radius: 5px;
      padding: 4px;
      font-size: 12px;
      width: 120px;
      display: inline-block;
      text-align: center;
  }
  
  .schedule {
    color: #01949A;
    background-color: #01959a2d;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    width: 120px;
    display: inline-block;
    text-align: center;
  }
  .completed {
    color: green;
    background-color: #49ea7f28;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    width: 120px;
    display: inline-block;
    text-align: center;
  }
  
  
  .hPriority {
    color: #DB1F48;
    background-color: #db1f4827;
    border-radius: 25px;
  }
  
  .mPriority {
    color: #e0e000;
    background-color: #ffffe0;
    border-radius: 25px;
  }
  
  .lPriority {
    color: #006400;
    background-color: #98ff98;
    border-radius: 25px;
  }
  .hPriority,
  .mPriority,
  .lPriority{
    width: 80px !important;
    display: inline-block;
    text-align: center;
    font-size:12px;
    padding: 4px ;
    margin-right: 5px;
    overflow: hidden;
  }
  .pr{
    width: 80px !important;
    display: inline-block;
    text-align: center;
    font-size:12px;
    padding: 4px ;
    margin-right: 5px;
   
  }
  
  .category {
    font-size: 12px;
    padding: 4px;
    color: #232b2b;
    background-color: #c4c3d0;
    border-radius: 25px;
    display: inline-block;
    width: 80px !important;
    text-align: center;
    overflow: hidden;
  }
  
  .del-bt{
    opacity: 0;
  }
  
  .table tbody tr:hover .del-bt{
  opacity: 1;
  }
  
  @media (max-width :900px) {
    .labels{
     display: block;
     width: 80px;
     margin-bottom: 5px;
    }
  }
  
  .imagesWrapper{
    display: flex;
    min-width: 150px;
  }
  .assignedImage{
    border-radius: 50%;
    height: 25px;
    aspect-ratio: auto 25 / 25;
    width: 25px;
    margin-left: 0.3rem !important;
  }