.firstTable{
    /* width: auto; */
    /* margin: -2rem 2rem !important; */
    /* font-size: large; */
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000;
    
  }
  .firstTable div{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .firstTable table{
    width: 80%;
  }
  .firstTable table thead td  {
    font-size: 18px;
    border-radius: 0;
  }
  .firstTable table thead td , 
  .firstTable table td{
    padding: 10px;
    border-radius: 0;
  }
  .firstTable table thead td{
    width: 33%;
    border-bottom: 1px solid #000;
  }

  .firstTable table thead td:nth-child(2),  
  .firstTable table td:nth-child(2){
    width: 67%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    border-right: 1px solid #000;
    border-left: 1px solid #000;

  }

  .secTwo{
    margin-top: 20px;
    border: 1px solid #000;

  }
  .secTwoContent{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
  }
  .secTwoContent:last-child{
    border-bottom: none;
  }
  .secTwoContent .leftTd{
    width: 30%;
    border-right: 1px solid #000;
    padding: 5px 10px;
    background-color:#d9d9d9 ;
  }
  .secTwoContent .leftTd h6{
    margin: 0;
    font-size: 14px;
}
  .secTwoContent .rightTd{
    width: 70%;
    padding: 2px 10px;
}
.secTwoContent .rightTd p{
    margin: 0;
}
.sectionHeader{
    text-align: center;
    padding: 10px;
    background-color: #404040;
    color: white;
}

.templateName{
    font-size: 24px;
    margin-bottom: 20px;
  } 
  .templateSection{
    margin-bottom: 20px;
  }
  .sectionTitle{
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0px;
    text-align: center;
    padding: 10px;
    background-color: #404040;
    color: white;
  } 
  .sectionFeilds{
    font-size: 16px;
  } 
  .sectionFeilds label{
    padding-left: 6px;
    width: 100%;
    text-align: left;
  }
  .templateCheckbox{
    display: flex;
    align-items: center;
  margin: 15px 0;
  }
  .templateCheckbox input{
    width: 40px;
    height: 30px;
    padding-left: 6px;
  }

  /* @media print {
    #pdf {
        display: block;
    }
    @page {
    size: auto; 
    margin: 15mm 0 15mm;
    }
} */

  /* .pdfheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 3px solid black;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  
  
  .signature{
    border-top: 1px solid black;
    display: inline-block;
    position: absolute;
    width: 200px;
  }
  
  .firstHalf{
    display: flex;
    justify-content: space-between;
  }  */